.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  background: var(--Neutral-2);
  position: relative;
  padding-top: 85px;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding-top: 70px;
  }
}
