.wrapper {
  padding: 80px 0;
  padding-top: 170px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.closeContainer,
.iconContainer {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--Neutral-7);
  margin-left: 45px;
}
.iconContainer {
  background: transparent;
  border: 2px solid var(--Neutral-3);
  transition: 0.3s;
}
.iconContainer:hover {
  border: 2px solid var(--Neutral-7);
}
.close,
.icon {
  color: var(--Neutral-1);
  font-size: 24px;
}
.icon {
  color: var(--Neutral-5);
}

.searchAndTitle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 12px;
}
.searchInput {
  flex-direction: row-reverse;
  gap: 12px;
  padding-left: 0;
}
.searchInput input {
  font-size: 24px;
  color: var(--Neutral-7);
}
.searchInput .searchIcon {
  color: var(--Neutral-7);
  font-size: 40px;
}
.searchInput input::placeholder {
  color: var(--Neutral-7);
  opacity: 1;
}
.statusAndPrice {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.statusContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.priceContainer {
  display: flex;
  align-items: center;
  gap: 32px;
}
.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.statusButton,
.priceButton,
.priceInput,
.maxButton {
  text-transform: capitalize;
  font-size: 16px;
  border: 2px solid var(--Neutral-3);
  border-radius: 4px;
  padding: 18px 24px;
  height: 60px;
}

.priceButton,
.maxButton,
.applyButton {
  font-size: 18px;
  flex: 1 1 0;
  min-width: 150px;
}
.applyButton {
  border: 2px solid var(--Neutral-7);
}
.button:hover,
.input:hover {
  border: 2px solid var(--Neutral-7);
}

.priceInput {
  flex: 1 1 0;
  min-width: 120px;
  transition: 0.3s;
}
.maxButton {
  color: var(--Neutral-4);
}
.priceInput input {
  text-align: center;
  font-size: 18px;
  color: var(--Neutral-7);
}

.applyButton {
  text-transform: capitalize;
  font-size: 16px;
}
.activeStatus {
  border: 2px solid var(--Accent-1);
}
.button {
  height: 60px;
}
.icon {
  font-size: 24px;
  color: var(--Neutral-5);
}
.activeStatus .icon {
  color: var(--Neutral-7);
}
.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 0;

  width: 100%;
}
.loadMoreButton {
  margin: 0 auto;
}
@media only screen and (max-width: 1440px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1199px) {
  .statusContainer,
  .priceContainer {
    gap: 16px;
  }
  .statusAndPrice {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 991px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .cards {
    grid-template-columns: 1fr;
    max-width: 450px;
    gap: 30px 40px;
  }
  .header {
    grid-template-columns: 1fr auto;
  }

  .iconContainer {
    order: 2;
  }
  .tabs {
    order: 3;
    grid-column: 1/-1;
  }
  .searchInput input {
    font-size: 16px;
  }
}
@media only screen and (max-width: 640px) {
  .searchAndTitle {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .container {
    gap: 48px;
  }
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding-top: 150px;
    padding-bottom: 60px;
  }

  .statusButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  .statusButton {
    padding: 18px 8px;
    gap: 8px;
  }
  .icon {
    font-size: 18px;
  }

  .priceButton {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .button {
    padding: 18px 16px;
  }
}
