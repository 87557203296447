.footer {
  margin-top: auto;
}

.wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr auto;
  gap: 40px;

  position: relative;

  border-top: 1px solid var(--Br-Dark);
  border-bottom: 1px solid var(--Br-Dark);
  padding-top: 80px;
  padding-bottom: 40px;
}
.logo {
  max-width: 218px;
  width: 100%;
}
.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;

  border-left: 1px solid var(--Br-Dark);
}
.logoContainer {
  padding-right: 40px;
  gap: 12px;
}

.heading {
  color: var(--Neutral-7);
  font-size: 12px;
  padding-bottom: 12px;
  font-weight: 800;
  line-height: 200%;
  letter-spacing: 2.04px;
}
.item {
  color: var(--Neutral-5);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142%;
  transition: 0.3s;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}
.socialIcon {
  font-size: 20px;
}
.item:hover {
  transform: translateY(-2px);
}
.bottomBar {
  border-top: 1px solid var(--Neutral-3);
}
.copyRightContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 32px 0;
}
.cookies {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}
.cookiesText,
.copyRight {
  color: var(--Neutral-4);
  white-space: wrap;
}
.acceptButton {
  margin-left: auto;
}
.acceptButton:hover {
  background: var(--Neutral-7);
  color: var(--Neutral-1);
  transform: translateY(-1);
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: auto 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr 1fr auto;
    gap: 50px 40px;
  }
  .logoContainer {
    padding-right: 0px;
    grid-column: 1/-1;
  }
  .heading {
    font-size: 16px;
  }
  .item {
    font-size: 14px;
  }
  .copyRightContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .cookies {
    gap: 20px;
  }
  .copyRight {
    grid-column: 1/-1;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .cookies {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;

    margin: 0 auto;
  }
  .heading {
    padding: 0;
  }
}
@media only screen and (max-width: 520px) {
  .copyRightContainer {
    gap: 12px;
  }
  .cookies {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .copyRight {
    text-align: center;
  }
  .cookiesText {
    text-align: center;
  }
  .acceptButton {
    margin: 0 auto;
  }
}
