.slide {
  position: relative;
  height: calc(100vh - 75px);
  min-height: 680px;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  pointer-events: auto;
}
.slide::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 23.41%,
    rgba(0, 0, 0, 0.54) 100%
  );
  z-index: 2;
}
.bottomBar {
  width: 90%;
  margin: 0 auto;
  margin-top: auto;
  padding: 80px 0;
  padding-top: 120px;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  gap: 40px;
  position: relative;
  z-index: 3;
}
.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: 1;
}
.leftSide {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.card {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: var(--Neutral-8);
  margin-left: auto;
}
.avater {
  width: 40px;
  border-radius: 12px;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.viewNftButton {
  padding: 20px 32px;
  border-color: var(--Neutral-1);
  color: var(--Neutral-1);
  max-width: 320px;
  width: 100%;
  height: 60px;
  cursor: disabled;
}

.placeABidButton {
  padding: 20px 32px;
  max-width: 320px;
  width: 100%;
  height: 60px;
}

.arrow {
  font-size: 20px;
}

@media only screen and (max-width: 991px) {
  .bottomBar {
    grid-template-columns: 1fr;
    padding-top: 75px;
  }

  .leftSide {
    max-width: 100%;
    width: 100%;
  }
  .viewNftButton,
  .placeABidButton {
    width: 100%;
    max-width: 100%;
    height: 50px;
    padding: 20px;
  }
  .viewNftButton:hover {
    background: var(--Neutral-7);
    color: var(--Neutral-1);
    border: 1px solid var(--Neutral-7);
  }
}
@media only screen and (max-width: 520px) {
  .buttonContainer {
    gap: 8px;
  }
  .slide {
    height: calc(100vh - 65px);
  }
}
