/* The container */
.container {
  display: flex;

  position: relative;
  padding-left: 28px;

  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;

  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 6px;

  border: 1px solid var(--Neutral-5);
  background: var(--Bg-Light-2);
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--Neutral-7);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;

  width: 3px;
  height: 9px;
  border: solid var(--Neutral-1);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media only screen and (max-width: 991px) {
  .checkmark {
    top: 3px;
  }
}
