@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter";
}
:root {
  --Primary-Default: #1966f7;
  --Accent-1: #bce6ec;
  --Accent-2: #b9a9fb;
  --Accent-3: #e77227;
  --Accent-4: #dbff73;

  --Neutral-1: #f7fbfa;
  --hover-color: #f7fbfa;
  --Neutral-2: #f1f4f4;
  --Neutral-3: #e1e2e2;
  --Neutral-4: #a6aead;
  --Neutral-5: #686a6c;
  --Neutral-6: #555;
  --Neutral-7: #202025;
  --Neutral-8: #010101;
  --radius: 16px;
  --bg: #202025;
}

.lightTheme {
  --Accent-1: #bce6ec;
  --Accent-2: #b9a9fb;
  --Accent-3: #e77227;
  --Accent-4: #dbff73;

  --Neutral-1: #f7fbfa;
  --Neutral-2: #f1f4f4;
  --Neutral-3: #e1e2e2;
  --Neutral-4: #a6aead;
  --Neutral-5: #686a6c;
  --Neutral-6: #555;
  --Neutral-7: #202025;
  --bg: #202025;
  --Neutral-8: #010101;
  --radius: 16px;
}
.darkTheme {
  --Neutral-1: #010101;
  --Neutral-2: #2d2d2d;
  --Neutral-3: #202025;
  --Neutral-4: #6e6e6e;
  --Neutral-5: #9e9e9e;

  --Neutral-7: #f7fbfa;
  --Neutral-8: #ffffff;
  --border: #f7fbfa;
  --radius: 16px;
  --bg: #202025;
}
#root {
  min-height: 100vh;
}
body {
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: var(--Neutral-2);
}
.container {
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
input {
  border: none;
  outline: none;
  background: transparent;
}
button,
a {
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
}
button:hover,
a:hover {
  opacity: 0.8;
  transform: translateY(-3px);
}
button:active {
  transform: translateY(0px);
}

::-webkit-scrollbar {
  width: 4px;
  background: rgba(250, 250, 250, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--Neutral-1);
  border-radius: 4px;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary-Default) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.overflow::-webkit-scrollbar-track {
  border-radius: 5px;
  width: 3px;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
  width: 3px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.highlight {
  color: var(--Primary-Default);
}
input::placeholder {
  color: var(--Text-Placeholder);
}
.swiper-fade .swiper-slide {
  pointer-events: auto !important;
  transition-property: all !important;
}
