.wrapper {
  height: 100%;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 0;
}
.info {
  margin-top: auto;
}
.buttonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px 20px;
}
.appleIcon {
  font-size: 20px;
  color: var(--Neutral-1);
}
.googleIcon {
  font-size: 20px;
  color: var(--Neutral-7);
}
@media only screen and (max-width: 991px) {
  .wrapper {
    background: var(--Accent-4);
  }
  .container {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 991px) {
  .button {
    width: 100%;
  }
}
