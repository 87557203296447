.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-right: auto;
}
.container {
  border-radius: 24px;
  background: var(--Neutral-1);
  box-shadow: 0px 16px 40px -8px rgba(0, 0, 0, 0.12);
  padding: 20px 40px;
  max-width: 420px;
  width: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 15px;
}
@media only screen and (max-width: 991px) {
  .container {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 20px 24px;
  }
}
