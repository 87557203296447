.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}

.nextAndPrevButton {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}
.prevButton,
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-radius: 60px;
  border: 2px solid var(--Neutral-3);
}
.arrow {
  font-size: 24px;
  color: var(--Neutral-7);
}
.activeButton {
  background: var(--Neutral-7);
  border: 1px solid var(--Neutral-7);
}
.activeButton .arrow {
  color: var(--Neutral-1);
}
@media only screen and (max-width: 1199px) {
  .prevButton,
  .nextButton {
    width: 48px;
    height: 48px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 60px 0;
  }
  .container {
    gap: 60px;
  }
}
