.navsAndThemeToggler {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  padding-top: 32px;
  border-bottom: 1px solid var(--Neutral-3);
  position: relative;
}
.navHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  cursor: pointer;
  position: relative;
  display: none;
}
.arrow {
  font-size: 20px;
  cursor: pointer;
  color: var(--Neutral-5);
}
.navs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 0;
}

.navContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 32px 32px;
  padding-top: 0;
  position: relative;
}
.navContainer:hover {
  transform: translateY(0);
}

.activeNav::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
  height: 1px;
  background: var(--Neutral-7);
}
.length {
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--Neutral-3);
  color: var(--Neutral-5);
  font-size: 16px;
}

@media only screen and (max-width: 1199px) {
  .wrapper .myNfts {
    padding-top: 100px;
  }
  .navsAndThemeToggler {
    padding-bottom: 10px;
  }
  .navHeader {
    display: flex;
    align-items: center;
  }
  .navs {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px;
    gap: 12px 0;
    border: none;
    display: none;
    padding: 24px 0;
  }
  .showDropdown {
    position: absolute;
    left: -5%;
    top: calc(100% + 5px);
    padding: 16px 5%;
    background: var(--Neutral-1);
    border-radius: 8px;
    /* width: calc(max-content); */
    min-width: 100%;
    display: grid;
    gap: 24px;
    z-index: 1;
    box-shadow: 0px 2rem 3rem -1rem rgba(0, 0, 0, 0.1);
  }
  .navContainer {
    padding-left: 0;
    padding-right: 0;
    padding: 0;

    border: none;
  }
  .activeNav::before {
    display: none;
  }
  .showDropdown .activeNav .navItem {
    color: var(--Primary-Default);
  }
}
@media only screen and (max-width: 991px) {
  .navsAndThemeToggler {
    padding-top: 32px;
  }
}
