.wrapper {
  height: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  padding: 80px 0;
  height: 100%;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: auto;
  width: 100%;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.info {
  grid-column: 1/-1;
}
.icon {
  cursor: pointer;
  font-size: 20px;

  color: var(--Neutral-5);
}
@media only screen and (max-width: 991px) {
  .wrapper {
    background: var(--Neutral-7);
  }
  .container {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 520px) {
  .inputContainer {
    grid-template-columns: 1fr;
  }
}
