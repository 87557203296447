.tabs {
  display: flex;
  align-items: center;
}
.tab {
  color: var(--Neutral-5);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.16px;
  padding: 8px 24px;
  border-radius: 40px;
}

.activeTab {
  background: var(--Neutral-7);
  color: var(--Neutral-1);
}
.tab:hover {
  transform: translateY(0);
}
@media only screen and (max-width: 520px) {
  .tab {
    padding: 8px 16px;
  }
}
