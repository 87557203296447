.wrapper {
  background: var(--Neutral-1);
}

.fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;

  height: 100vh;

  z-index: 10000;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  font-size: 24px;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 650px;
}

.imgContainer {
  position: relative;
}

.img {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
  object-position: center;
}

.topBar {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 1;
}

.music,
.fullScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: rgba(247, 251, 250, 0.7);
  cursor: pointer;
}

.musicIcon,
.fullScreenIcon {
  width: 24px;
}

.infoContainer {
  padding: 80px 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.createdByAndCollection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  z-index: 1;
}

.createdBy,
.collection {
  display: grid;
  grid-template-columns: auto 1fr auto;

  gap: 12px;
}

.label {
  grid-column: 1/-1;
}

.authorImgContainer {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* background: var(--Neutral-1); */

  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authorImgContainer::before {
  content: " ";
  position: absolute;
  width: calc(100% + 0.5px);
  height: calc(100% + 0.5px);
  border-radius: 50%;
  z-index: -1;
}
.hr {
  border: none;
  height: 1px;
  background: var(--Neutral-3);
  width: 100%;
}
.link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
}
.icon {
  color: var(--Neutral-7);
  font-size: 20px;
}
@media only screen and (max-width: 1199px) {
  .infoContainer {
    padding: 60px 0;
    padding-top: 0;
  }

  .detailsContainer,
  .infoContainer {
    gap: 60px;
  }
}

@media only screen and (max-width: 520px) {
  .infoContainer {
    padding: 40px 0;
  }
  .infoContainer {
    padding-top: 0;
  }
  .detailsContainer,
  .infoContainer {
    gap: 40px;
  }
}
