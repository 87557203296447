.wrapper {
  padding-bottom: 80px;
}
.container {
  background: var(--Neutral-1);
}

.navsAndThemeToggler {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  padding-top: 32px;
  border-bottom: 1px solid var(--Neutral-3);
  position: relative;
}

.iconContainer {
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--Neutral-3);
  border-radius: 50%;
  margin-left: auto;
  transition: 0.3s;
  cursor: pointer;
}

.iconContainer:hover {
  border: 2px solid var(--Neutral-7);
  transform: translateY(0);
}

.icon {
  font-size: 24px;
  color: var(--Neutral-5);
}

.wrapper .myNfts {
  padding: 64px 0;
  padding-top: 128px;
}
@media only screen and (max-width: 1199px) {
  .wrapper .myNfts {
    padding-top: 100px;
  }
  .navsAndThemeToggler {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper .myNfts {
    padding: 64px 0;
    padding-top: 80px;
  }

  .navsAndThemeToggler {
    padding-top: 32px;
  }
}

@media only screen and (max-width: 767px) {
}
